import React, { useEffect, useState } from "react";
import "./Home.scss";

import Footer from "../../Components/Footer/Footer";
import { polyglot } from "../../i18n";

const Home = ({ selectedLanguage }) => {

  useEffect(() => {
    polyglot.locale(selectedLanguage);
    console.log(selectedLanguage); 
  }, [selectedLanguage]);

  return (
    <>
      <div className="container-all-home">
        {/* <img src={textura} /> */}
        <div className="container-notes">
          <h4 className="parrafo-1">
          {polyglot.t('text_home')}
          </h4>
        </div>
        <div id="noticias" className="container-section-noticias">
          {/* <CarouselComponent/> */}
        </div>

       
      </div>
    </>
  );
};

export default Home;

import React from "react";
import copyright from "../../assets/logos/copyright.png";
import "./Footer.scss";
import facebook from '../../assets/logos/facebook.png'
import twitter from '../../assets/logos/twiter.png'
import instagram from '../../assets/logos/instagram.png'
import youtube from '../../assets/logos/youtube.png'
import tiktok from '../../assets/logos/tiktok.png'


const Footer = () => {
  return (
    <div className="footer">
      <div className="container-footer">
        <img src={copyright} /> La Corona Sports Media
      </div>
      <div class="NetworkSocialIcons">
        <a
          href="https://www.facebook.com/LaCoronaSM"
          target="_blank"
        >
          <img
            src={facebook}
            alt="btn-facebook"
          />
        </a>
        <a href="https://twitter.com/laCoronaSM" target="_blank">
          <img
            src={twitter}
            alt="btn-twitter"
          />
        </a>
        <a href="https://www.tiktok.com/@lacoronasm" target="_blank">
          <img
            src={tiktok}
            alt="btn-twitter"
          />
        </a>
        <a href="https://www.instagram.com/lacoronasm/" target="_blank">
          <img
            src={instagram}
            alt="btn-twitter"
          />
        </a>
        <a href="https://www.youtube.com/@laCoronaSM" target="_blank">
          <img
            src={youtube}
            alt="btn-twitter"
          />
        </a>
        
      </div>
    </div>
  );
};

export default Footer;

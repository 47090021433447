import React from 'react'
import './Contact.scss'
import facebook from '../../assets/logos/facebook.png'
import instagram from '../../assets/logos/instagram.png'
import tiktok from '../../assets/logos/tiktok.png'
import twiter from '../../assets/logos/twiter.png'
import youtube from '../../assets/logos/youtube.png'
import bannerContacto from "../../assets/images/banners/Banner_Contacto.jpg";
import { polyglot } from '../../i18n'



const Contact = () => {
  return (
    <div className='container-contact'>
      <div className="banner_aboutUs">
        <img src={bannerContacto} />
      </div>
      <div className='title-contact'>
         <h1> {polyglot.t('contact_title')} </h1>
      </div>
      <div className='text_contact'>
        <p>{polyglot.t('contact_text')}</p>
        <span>{polyglot.t('span_contact_text')}</span>
      </div>
    
    </div>
  )
}

export default Contact
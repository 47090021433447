import React from "react";
import "./Noticias.scss";
import bannerNoticia from "../../assets/images/banners/Banner_Noticias.jpg";
import { polyglot } from "../../i18n";


const Noticias = () => {
  return (
    <>
      <div className="container-noticias">
      <div className="banner_aboutUs">
        <img src={bannerNoticia} />
      </div>
      <div className="title">
        <h1>{polyglot.t("news_title")}</h1>
      </div>
      <div className="container_text_new">
        <p>{polyglot.t('news_text')}</p>
      </div>
      </div>
      
    </>
  );
};

export default Noticias;

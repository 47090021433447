import React, { useEffect } from "react";
import "./AboutUs.scss";
import textura from "../../assets/images/textura3.jpg";
import bannerChamba from "../../assets/images/banners/Banner_NuestraChamba.jpg";
import bannerChambaMobile from "../../assets/images/banners/750x522_Chamba.jpg";
import foto1 from "../../assets/images/quehacemos/Quehacemos.jpg";
import foto2 from "../../assets/images/quehacemos/2.jpg";
import foto3 from "../../assets/images/quehacemos/5_1.jpg";
import foto4 from "../../assets/images/quehacemos/5.jpg";
import foto5 from "../../assets/images/quehacemos/6.jpg";
import foto6 from "../../assets/images/quehacemos/ame3.jpg";
import foto7 from "../../assets/images/quehacemos/AmeTig-1.jpg";
import foto8 from "../../assets/images/quehacemos/bravas4.jpg";
import foto9 from "../../assets/images/quehacemos/Cruz-Azul-vsAtlas-4.jpg";
import foto10 from "../../assets/images/quehacemos/Cruz-Azul-vsAtlas-9.jpg";
import foto11 from "../../assets/images/quehacemos/DSC_8835-Enhanced-NR.jpg";
import foto12 from "../../assets/images/quehacemos/naranja11copia2.jpg";
import foto13 from "../../assets/images/quehacemos/naranja11copia5.jpg";
import foto14 from "../../assets/images/quehacemos/pumas2.jpg";
import foto15 from "../../assets/images/quehacemos/X-Estadui.jpg";
import { polyglot } from "../../i18n";
import PhotoAlbum from "react-photo-album";
import YouTubePlayer from "react-youtube";

const AboutUs = ({ selectedLanguage, isMobile }) => {
  useEffect(() => {
    polyglot.locale(selectedLanguage);
    console.log(selectedLanguage);
  }, [selectedLanguage]);

  const photos = [
    { src: foto2, width: 800, height: 800 },
    { src: foto3, width: 800, height: 800 },
    { src: foto4, width: 900, height: 900 },
    { src: foto5, width: 1000, height: 900 },
    { src: foto6, width: 900, height: 900 },
    { src: foto7, width: 1000, height: 800 },
    { src: foto8, width: 800, height: 900 },
    { src: foto9, width: 900, height: 900 },
    { src: foto10, width: 1000, height: 1200 },
    { src: foto11, width: 1200, height: 1200 },
    { src: foto12, width: 900, height: 600 },
    { src: foto13, width: 800, height: 600 },
    { src: foto14, width: 800, height: 800 },
    { src: foto15, width: 800, height: 600 },
  ];

  const videoOptions = {
    width: "360",
    height: "315",
    playerVars: {
      autoplay: 0,
    },
  };
  const videoOptions2 = {
    width: "260",
    height: "505",
    playerVars: {
      autoplay: 0,
    },
  };
  return (
    <div className="container-all-aboutUs">
      {/* <img src={textura} /> */}
      <div className="banner_aboutUs">
        <img src={isMobile ? bannerChambaMobile :  bannerChamba} />
      </div>
      <div className="title">
        <h1>{polyglot.t("corona_job_title")}</h1>
      </div>
      <div className="container_1_job">
        <div className="container_1_text_job">
          <p>{polyglot.t("corona_job_text")}</p>
        </div>
        <div className="container_img_1">
          <img src={foto1} />
        </div>
      </div>
      <div className="container_2_job">
        <p>{polyglot.t("corona_job_text2")}</p>
      </div>
      <div className="container_2_photos">
        <PhotoAlbum layout="columns" photos={photos} />
      </div>
      <div className="container_3_job">
        <p>{polyglot.t("corona_job_text3")}</p>
      </div>
      <div className="container_3_videos">
        <div className="container_3_videos_cont">
          <YouTubePlayer videoId="7ijQlxv-oHA" opts={videoOptions} />
          <YouTubePlayer videoId="myMP8CKkVDA" opts={videoOptions} />
        </div>
        <div className="container_3_videos_cont_2">
          <YouTubePlayer videoId="QAplQjqYRMQ" opts={videoOptions2} />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

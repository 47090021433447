import Polyglot from 'node-polyglot';
import translationsEn from './i18n/en.json'; // Importa el archivo JSON con las traducciones en inglés
import translationsEs from './i18n/es.json'; // Importa el archivo JSON con las traducciones en español

// Crea una instancia de Polyglot y configura las traducciones en inglés
const polyglot = new Polyglot({ phrases: translationsEs });

polyglot.locale('es');

function changeLanguage(language) {
  if (language === 'es') {
    polyglot.replace(translationsEs);
  } else if (language === 'en') {
    polyglot.replace(translationsEn);
  }

  polyglot.locale(language);
}

export { polyglot, changeLanguage };

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import corona from "../../assets/images/CORONA.png";
import { animateScroll as scroll, scroller } from "react-scroll";
import "./NavBar.scss";
import { LANGUAGES } from "../../constants/languajes.js"; // Asumiendo que tienes una lista de idiomas en LANGUAGES
import { polyglot, changeLanguage } from '../../i18n.js'; // Importa las funciones de traducción desde tu archivo i18n

const NavBar = ({ selectedLanguage, setSelectedLanguage }) => { // Recibe selectedLanguage y setSelectedLanguage como props
  const [click, setClick] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleChangeLanguage = (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage); // Actualiza el estado local
    changeLanguage(newLanguage); // Actualiza el idioma en Polyglot
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 100,
      smooth: true,
      spy: true,
    });
  };

  const handleLogoClick = () => {
    closeMobileMenu();
    scrollToSection("home");
  };

  return (
    <>
      <nav className="navbar">
        <div className="icon-title">
          <Link to="/" className="navbar-logo" onClick={handleLogoClick}>
            <img className="img-logo" src={corona} alt="logo" />
          </Link>
          <h1>La Corona Sports Media</h1>
        </div>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={handleLogoClick}>
              {polyglot.t('home')}
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/aboutUs" className="nav-links">
              {polyglot.t('corona_job_title')}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/news" className="nav-links">
              {polyglot.t('news_title')}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links">
              {polyglot.t('contact_title')}
            </Link>
          </li>
          <div>
            <select value={selectedLanguage} onChange={handleChangeLanguage}>
              {LANGUAGES.map(({ code, label }) => (
                <option key={code} value={code}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;

import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home'
import Contact from './Pages/Contacto/Contact';
import NavBar from './Components/NavBar/NavBar';
import AboutUs from './Pages/AboutUs/AboutUs';
import Footer from './Components/Footer/Footer';
import Prueba from './Pages/Prueba/Prueba';
import { Suspense, useEffect, useState } from 'react';
import Noticias from './Pages/Noticias/Noticias';
// import Photos from './Pages/Photos/Photos';

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState('es'); 
  const [isMobile, setIsmobile] = useState();

  useEffect(() => {
    const checkIsMobile = () => {
      setIsmobile(window.innerWidth < 768); // Puedes ajustar el valor según tus necesidades
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <Router>
      <NavBar selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}/>
      <Routes>
        <Route exact path="/" element={<Home selectedLanguage={selectedLanguage}/>} />
        <Route  path="/aboutUs" element={<AboutUs selectedLanguage={selectedLanguage} ismobile={isMobile}/>} />
        <Route  path="/news" element={<Noticias selectedLanguage={selectedLanguage} ismobile={isMobile}/>} />
        <Route  path="/contact" element={<Contact selectedLanguage={selectedLanguage}/>} />
      </Routes>
      <Footer/>
      
    </Router>
  );
}

export default App;
